import { render, staticRenderFns } from "./KensingtonLocations.vue?vue&type=template&id=18303302&scoped=true"
import script from "./KensingtonLocations.vue?vue&type=script&lang=js"
export * from "./KensingtonLocations.vue?vue&type=script&lang=js"
import style0 from "./KensingtonLocations.vue?vue&type=style&index=0&id=18303302&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18303302",
  null
  
)

export default component.exports