<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-location-pin"/>
                  Alle Kensington Standorte
                </div>
                <div class="header-button">
                  <CButton
                      to="kensington-locations/new"
                      name="backagain"
                      size="sm"
                      key="key"
                      color="dark">
                    <CIcon size="sm" name="cil-plus"/>
                    <span> Kensington Standort hinzufügen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                :items="tableData"
                :fields="getTableFields()"
                hover
                striped
                bordered
                fixed
                column-filter
                table-filter
                items-per-page-select
                sorter
                pagination
                :loading="tableShowLoading"
            >
              <template #postalCodeList="{item}">
                <td>
                  {{ item.postalCodeList.join(', ') }}
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                      :to="'kensington-locations/edit/' + item.id"
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                  >
                    Edit
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'KensingtonLocations',
  components: {},
  data() {
    return {
      tableData: [],
      tableShowLoading: true,
    }
  },
  mounted() {
    axios({ method: 'GET', url: '/kensington/location', baseURL: process.env.VUE_APP_PLUGIN_BASE_URL })
        .then(response => {
          this.tableData = response.data
          this.tableShowLoading = false
        })
        .catch(err => console.error('Problem while fetching kensington locations', err))
  },
  methods: {
    getTableFields() {
      return [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'E-Mail' },
        { key: 'firstName', label: 'Vorname' },
        { key: 'lastName', label: 'Nachname' },
        { key: 'postalCodeList', label: 'Postleitzahl(en)' },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    getTableBadge (status) {
      return status === 'ACTIVE' ? 'success'
          : status === 'INACTIVE' ? 'secondary'
              : status === 'PENDING' ? 'warning'
                  : status === 'BANNED' ? 'danger' : 'primary'
    }
  }
}
</script>

<style scoped>
.prt-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.prt-card-header > div {
  text-align: right;
}
</style>
